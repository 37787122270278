import { ApiGetMCADLicensesResponse } from "../../../../api/api/admin-web/mcad/licenses"
import { useState, useCallback, useEffect, useMemo } from 'react';
import { apiAdmin, ApiManager } from '../../../../managers/api-manager';

export type MCadLicenseData = {
  limit: number,
  excludeCompanyUseCount: number,
}

export const useGetPCadLicenses = (companyCode?: string) => {
  const [info, setInfo] = useState<ApiGetMCADLicensesResponse | null>(null);
  // const [info, setInfo] = useState<PCadLicenseResponseDataEntity | null>({
  //   cadect_use_count: 20,
  //   total_cadect_use_count: 100,
  //   licence_limit: 100,
  // });

  const pCadLicense: MCadLicenseData | null = useMemo(() => {
    if (!info) return null;
    return {
      limit: info.license_limit,
      excludeCompanyUseCount: info.license_limit - (info.total_cadect_use_count - info.cadect_use_count),
    }
  }, [info]);

  const getLicense = useCallback((callback?: () => void) => {
    const apiBase = apiAdmin.mcad().licenses();
    const api = companyCode ? apiBase.getDetail : apiBase.get;
    api(companyCode ?? '').then((res) => {
      setInfo(res.body.data);
    // }).catch((e) => ApiManager.errorFunc(e));
    }).catch((e) => ApiManager.errorFunc(e));
  }, [companyCode]);

  useEffect(() => {
    getLicense();
  }, [getLicense]);

  return {
    pCadLicense,
    getLicense,
  }
}
