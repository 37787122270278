import con from '../../../../connection';
import { BaseResponseType } from '../../../../types/base-types';

export type ApiGetMCADLicensesResponse = {
  /** 
   * 全体: 0固定
   * 企業指定: 指定された企業に付与されているライセンス数
   */
  cadect_use_count: number;
  /** 登録済み企業に付与済のプランライセンス総数 */
  total_cadect_use_count: number;
  /** 三菱重工全体で利用できるライセンス数 */
  license_limit: number;
}

const path = '/licenses';
const licenses = (url: string) => () => ({
  get: () =>
    con.get<BaseResponseType<ApiGetMCADLicensesResponse>>(url + path, {}, true),
  getDetail: (companyCode: string) => 
    con.get<BaseResponseType<ApiGetMCADLicensesResponse>>(url + path + `/${companyCode}`, {}, true),
});

export default licenses;
